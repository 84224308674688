import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper-https";
import NotificationHelper from "../helpers/NotificationHelper";
import ApiErrorHelper from "../helpers/ApiErrorHelper";
import {useLazyGetLabelsToPrintQuery} from "../redux/api/salesorders";
import {useTranslation} from "react-i18next";

const useSendPrintCommand = () => {
    const {t} = useTranslation();
    const [getLabelData] = useLazyGetLabelsToPrintQuery();

    return async (salesOrderId: number) => {
        try {
            const browserPrint = new ZebraBrowserPrintWrapper();
            const defaultPrinter = await browserPrint.getDefaultPrinter();
            browserPrint.setPrinter(defaultPrinter);
            const printerStatus = await browserPrint.checkPrinterStatus();
            const pdf: Blob = await getLabelData({id: salesOrderId}).unwrap();

            if (printerStatus.isReadyToPrint) {
                const formData = new FormData();
                formData.append('json', JSON.stringify({
                    device: defaultPrinter,
                }));
                formData.append('blob', pdf, 'label.pdf');

                await fetch('http://127.0.0.1:9100/write', {
                    method: 'POST',
                    body: formData
                });

                NotificationHelper.showSuccess(t('notification:sentLabelsToPrinter'));
            } else {
                ApiErrorHelper.processErrors({
                    status: 400,
                    data: {
                        message: t('notification:notAbleToPrint')
                    }
                });
            }
        } catch (error) {
            ApiErrorHelper.processErrors({
                status: 400,
                data: {
                    message: t('notification:notAbleToPrint')
                }
            });
        }
    }

}

export default useSendPrintCommand;